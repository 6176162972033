<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="card mb-0">
      <div class="card-header">
        <router-link to="/profile/billing" type="button">
          <i class="dropdown-icon si si-arrow-left"></i>
        </router-link>
        <h3 class="card-title">Fatura Detayları</h3>
      </div>
      <div class="card-body">
        <div class="panel-group1">
          <h2></h2>
          <div
            class="panel panel-default mb-4 border p-0"
            v-for="(detail, i) in $store.state.paymentInfo.detail"
            :key="'billing_detail_' + i"
            :id="'accordion' + i"
          >
            <div class="panel-heading1">
              <h4 class="panel-title1">
                <a
                  class="accordion-toggle collapsed"
                  data-bs-toggle="collapse"
                  :data-parent="'#accordion' + i"
                  :href="'#collapse' + i"
                  aria-expanded="false"
                  >#{{ detail.id }}</a
                >
              </h4>
              <div
                :id="'collapse' + i"
                class="panel-collapse collapse active"
                role="tabpanel"
                aria-expanded="false"
              >
                <div class="panel-body bg-white">
                  <b>Adı Soyadı:</b> {{ detail.fullname }}<br />
                  <b>Randevu Tarihi:</b> {{ detail.appointment_date }}<br />
                  <b>Oluşturma Tarihi:</b> {{ detail.created_at }}<br />
                  <hr style="border-top: 1px dashed; padding: 0; margin: 0" />
                  <div style="text-align: right">
                    <b>Vergi:</b> %{{ detail.tax_ratio }}
                  </div>
                  <div style="text-align: right">
                    <b>Fiyat:</b> {{ detail.price }}TL
                  </div>
                  <div style="text-align: right">
                    <b>Vergi:</b> {{ detail.tax }}TL
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        Toplam {{ $store.state.paymentInfo.detail.length }} randevu
      </div>
      <div class="card-footer">
        <div class="row" style="text-align: right">
          <div class="col-md-12">
            <h5>
              TUTAR :
              {{
                $store.state.paymentInfo.price -
                $store.state.paymentInfo.tax_price
              }}
              TL
            </h5>
          </div>
          <div class="col-md-12">
            <h5>K.D.V. : {{ $store.state.paymentInfo.tax_price }} TL</h5>
          </div>
          <div class="col-md-12">
            <h2>TOPLAM : {{ $store.state.paymentInfo.price }} TL</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  created() {
    document.title = "Ödeme Yap";
    this.getLink();
  },
  data() {
    return {};
  },

  methods: {
    getLink() {
      let info = {
        id: this.$route.params.id,
      };
      this.$store.dispatch("billingGet", info).then((value) => {
        this.$store.state.paymentInfo = value[0];
      });
    },
  },
  components: {},
  mounted() {},
};
</script>